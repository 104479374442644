.card-benefit {
  $self: &;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 50px 30px;
  border: 1.5px solid $mischka;
  background-color: $white;
  border-radius: $radiusBig;
  overflow: hidden;

 @include cardMobPaddings;

  &_img-bottom {
    #{$self} {
      &__text {
        margin-bottom: 55px;
      }

      &__img {
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: $mirage;

    @include below($xl-max) {
      font-size: 46px;
    }

    @include below($lg-max) {
      font-size: 40px;
    }

    @include below($md-max) {
      font-size: 36px;
    }

    span {
      color: $green;
    }
  }

  &__text {
    margin-top: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $mulledWine;

    @include below($xs-max) {
      font-size: 20px;
    }

    br {
      @include below($md-max) {
        display: none;
      }
    }
  }

  &__img {
    display: block;
    margin: 45px auto 0;
  }
}