.svg-k2g {
  display: inline-block;
  background-image: url("https://45045908.fs1.hubspotusercontent-na1.net/hubfs/45045908/k2g-website/images/sprites/sprite.svg?ver=1") !important;
}

.ellipse-decor {
  pointer-events: none;

  @include below($xs-max) {
    display: none;
  }
}