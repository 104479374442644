.k2g {
  &-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: $mirage;

    span {
      color: $green;
    }

    @include below($md-max) {
      font-size: 36px;
    }

    br {
      @include below($md-max) {
        display: none;
      }
    }

    &_lg {
      font-size: 64px;

      @include below($md-max) {
        font-size: 46px;
      }

      @include below($xs-max) {
        font-size: 40px;
      }
    }

    &_xxl {
      font-size: 96px;
      line-height: 110%;
      letter-spacing: -1.92px;

      @include below($xl-max) {
        font-size: 76px;
      }

      @include below($md-max) {
        font-size: 56px;
      }

      @include below($xs-max) {
        font-size: 46px;
      }
    }

    &_xl {
      font-size: 72px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -1.44px;

      @include below($xl-max) {
        font-size: 62px;
      }

      @include below($md-max) {
        font-size: 52px;
      }

      @include below($xs-max) {
        font-size: 42px;
      }
    }
  }

  &-text {
    font-size: 20px;
    line-height: 150%;
    color: $mulledWine;

    br {
      @include below($md-max) {
        display: none;
      }
    }

    &_lg {
      font-size: 24px;
    }

    &_mt-10 {
      margin-top: 10px;
    }

    &_mt-20 {
      margin-top: 20px;
    }
  }

  &-content {
    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
      line-height: 120%;
      color: $mirage;

      span {
        color: $green;
      }

      br {
        @include below($md-max) {
          display: none;
        }
      }
    }

    h1, h2 {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h3, h4, h5, h6, p, blockquote {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
    }

    h1 {
      font-size: 72px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -1.44px;

      @include below($xl-max) {
        font-size: 62px;
      }

      @include below($md-max) {
        font-size: 52px;
      }

      @include below($xs-max) {
        font-size: 42px;
      }
    }

    h2 {
      font-size: 64px;

      @include below($md-max) {
        font-size: 46px;
      }

      @include below($xs-max) {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 48px;

      @include below($md-max) {
        font-size: 36px;
      }
    }

    h4 {
      font-size: 40px;

      @include below($md-max) {
        font-size: 34px;
      }
    }

    h5, h6 {
      font-size: 36px;

      @include below($md-max) {
        font-size: 30px;
      }
    }

    p, ul, ol {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: $mulledWine;
    }

    a {
      color: $green;

      &:hover {
        color: $mirage;
      }
    }

    ul, ol {
      list-style: initial;
      padding-left: 30px;
    }

    ol {
      list-style: decimal;
    }

    p strong {
      font-weight: 600;
    }

    img {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
      height: auto;
    }

    table {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid $mischka;
      font-size: 20px;
      line-height: 150%;
      color: $mulledWine;

      th, td {
        padding: 8px;
      }

      th {
        border-bottom: 1px solid $mischka;
      }
    }

    hr {
      margin-top: 10px;
      margin-bottom: 20px;
      border-color: $mischka;
    }
  }
}