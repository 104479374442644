.k2g-form {
  form {
    .hs-richtext {
      margin-bottom: 15px;

      h3, h5 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        color: $mirage;
      }
    }

    .hs-fieldtype-select {
      label {
        span {
          font-size: 20px;
          line-height: 120%;
          color: $black;
        }
      }
    }

    .hs_use_case {
      > label {
        display: inline-block;
        margin-bottom: 30px;
      }
    }

    .field {
      margin-bottom: 10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="submit"],
    select,
    textarea {
      width: 100% !important;
      padding: 12px;
      border: 1px solid $mischka;
      border-radius: 6px;
      background: $alabaster;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      @include below($xs-max) {
        padding: 15px;
      }

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: unset;
        border: 1px solid $green;
      }

      &::placeholder {
        color: $cadetBlue;
        font-weight: 300;
      }
    }

    input[type="submit"] {
      border: unset;
      background: $gradientGreen;
      color: $white;
      text-decoration: none !important;
      text-align: center;
      cursor: pointer;
      font-weight: 300;

      &:hover {
        color: $mirage;
      }
    }

    textarea {
      height: 150px;
    }

    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 25px!important;
      height: 25px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS41IiB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHJ4PSI1IiBzdHJva2U9IiM2RTcxOTEiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;

      &:checked {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMS41IiB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHJ4PSI1IiBzdHJva2U9IiM2RTcxOTEiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNOS43MzA0NyAxOC4yODEyQzEwLjAwMzkgMTguNTU0NyAxMC40Njg4IDE4LjU1NDcgMTAuNzQyMiAxOC4yODEyTDE4Ljc4MTIgMTAuMjQyMkMxOS4wNTQ3IDkuOTY4NzUgMTkuMDU0NyA5LjUwMzkxIDE4Ljc4MTIgOS4yMzA0N0wxNy43OTY5IDguMjQ2MDlDMTcuNTIzNCA3Ljk3MjY2IDE3LjA4NTkgNy45NzI2NiAxNi44MTI1IDguMjQ2MDlMMTAuMjUgMTQuODA4Nkw3LjE2MDE2IDExLjc0NjFDNi44ODY3MiAxMS40NzI3IDYuNDQ5MjIgMTEuNDcyNyA2LjE3NTc4IDExLjc0NjFMNS4xOTE0MSAxMi43MzA1QzQuOTE3OTcgMTMuMDAzOSA0LjkxNzk3IDEzLjQ2ODggNS4xOTE0MSAxMy43NDIyTDkuNzMwNDcgMTguMjgxMloiIGZpbGw9IiM2RTcxOTEiLz4KPC9zdmc+Cg==');
      }
    }

    select {
      position: relative;
      padding-right: 35px;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDY4NzUgOC45NjU1OEwwLjM3NSAyLjkwMzA4QzAuMDkzNzUgMi41OTA1OCAwLjA5Mzc1IDIuMTIxODMgMC4zNzUgMS44NDA1OEwxLjA5Mzc1IDEuMTIxODNDMS4zNzUgMC44NDA1NzYgMS44NDM3NSAwLjg0MDU3NiAyLjE1NjI1IDEuMTIxODNMNyA1LjkzNDMzTDExLjgxMjUgMS4xMjE4M0MxMi4xMjUgMC44NDA1NzYgMTIuNTkzOCAwLjg0MDU3NiAxMi44NzUgMS4xMjE4M0wxMy41OTM4IDEuODQwNThDMTMuODc1IDIuMTIxODMgMTMuODc1IDIuNTkwNTggMTMuNTkzOCAyLjkwMzA4TDcuNSA4Ljk2NTU4QzcuMjE4NzUgOS4yNDY4MyA2Ljc1IDkuMjQ2ODMgNi40Njg3NSA4Ljk2NTU4WiIgZmlsbD0iIzE0MTQyQiIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-position: calc(100% - 12px) center;
      color: $cadetBlue;

      &:focus,
      &:focus-visible,
      &:focus-within {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNTMxMjUgMS4wOTA4MkwxMy42MjUgNy4xNTMzMkMxMy45MDYyIDcuNDY1ODIgMTMuOTA2MiA3LjkzNDU3IDEzLjYyNSA4LjIxNTgyTDEyLjkwNjIgOC45MzQ1N0MxMi42MjUgOS4yMTU4MiAxMi4xNTYyIDkuMjE1ODIgMTEuODQzOCA4LjkzNDU3TDcgNC4xMjIwN0wyLjE4NzUgOC45MzQ1N0MxLjg3NSA5LjIxNTgyIDEuNDA2MjUgOS4yMTU4MiAxLjEyNSA4LjkzNDU3TDAuNDA2MjUgOC4yMTU4MkMwLjEyNSA3LjkzNDU3IDAuMTI1IDcuNDY1ODIgMC40MDYyNSA3LjE1MzMyTDYuNSAxLjA5MDgyQzYuNzgxMjUgMC44MDk1NyA3LjI1IDAuODA5NTcgNy41MzEyNSAxLjA5MDgyWiIgZmlsbD0iIzE0MTQyQiIvPgo8L3N2Zz4K');
      }

      option {
        color: $black;

        &:disabled {
          color: $cadetBlue;
        }
      }
    }

    fieldset {
      &.form-columns-1 {
        .hs-input:not(input[type="checkbox"]) {
          width: 100% !important;
        }
      }
    }

    .legal-consent-container .hs-form-booleancheckbox-display {
      p,span {
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: $mirage;

        a {
          color: $mirage;

          &:hover {
            color: $green;
          }
        }
      }

      > span {
        margin-left: 30px;
      }
    }

    .hs-form-booleancheckbox {
      margin-bottom: 5px;
    }

    .inputs-list.multi-container {
      margin-top: 10px;
    }

    .hs-form-checkbox {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-size: 18px;
          line-height: normal;
          color: $stormGray;
        }
      }
    }

    .hs-error-msg,
    .hs-error-msgs {
      font-size: 14px;
      font-weight: 300 !important;
      line-height: normal;
      color: $pink;

      label {
        color: $pink;
      }
    }

    .hs_error_rollup {
      color: $pink;

      li {
        font-size: 14px;
        line-height: normal;
        font-weight: 300 !important;
      }
    }
  }

  .submitted-message {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    color: $green;
  }
}