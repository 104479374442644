.footer {
  padding-top: 45px;
  padding-bottom: 35px;
  border-top: 1.5px solid $mischka;

  &__logo-row {
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__menu-row {
    row-gap: 50px;

    @include below($md-max) {
      row-gap: 40px;
    }
  }

  &__bottom-row {
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $mirage;

    @include above($lg-min) {
      min-height: 40px;
    }
  }

  &__menu {
    ul {
      li {
        line-height: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        a {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $mulledWine;

          &:hover {
            color: $green;
          }
        }
      }
    }

    &_line {
      ul {
        display: flex;
        justify-content: end;
        gap: 25px;

        @include below($md-max) {
          justify-content: start;
          margin-top: 10px;
          flex-wrap: wrap;
          row-gap: 10px;
        }

        li {
          margin-bottom: 0 !important;

          a {
            &:not(:hover) {
              color: $cadetBlue;
            }
          }
        }
      }
    }
  }

  &__email {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $mulledWine;

    &:hover {
      color: $green;
    }
  }

  &__social {
    margin-top: 10px;
    line-height: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }

    i {
      filter: $filterMirage;
      transition: filter 0.3s ease;
    }

    &:hover {
      i {
        filter: $filterGreen;
      }
    }
  }

  &__copy {
    font-size: 14px;
    line-height: 20px;
    color: $cadetBlue;
  }

  &__stripe {
    padding-top: 25px;
    margin-top: 40px;
    border-top: 1px solid $mischka;
  }
}