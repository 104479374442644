.review {
  padding-top: 100px;
  padding-bottom: 100px;

  @include paddingsLg50;
  @include paddingsMob25;

  &__row {
    justify-content: center;
    text-align: center;
  }

  &__text {
    margin-top: 40px;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    color: $mirage;

    @include below($lg-max) {
      font-size: 36px;
      line-height: 120%;
    }

    @include below($md-max) {
      margin-top: 10px;
      font-size: 28px;
    }
  }

  &__author {
    &-img {
      margin-top: 30px;
      width: 65px;
      height: 65px;
    }

    &-name {
      margin-top: 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: $mirage;
    }

    &-position {
      color: $stormGray;
    }
  }
}