.side-to-side {
  $self: &;
  position: relative;
  z-index: 1;
  padding-top: 155px;
  padding-bottom: 170px;

  @include below($xl-max) {
    padding-bottom: 100px;
  }

  @include below($lg-max) {
    padding-top: 75px;
    padding-bottom: 50px;
  }

  @include paddingsMob25;

  &__decor {
    position: absolute;
    top: 18%;
    left: 0;
    z-index: -1;
  }

  &-row {
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    row-gap: 30px;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    &:not(:first-child) {
      margin-top: 255px;

      @include below($lg-max) {
        margin-top: 80px;
      }

      @include below($md-max) {
        margin-top: 40px;
      }
    }

    &:first-child {
      #{$self}-row__decor {
        top: -51%;
        right: 33%;
      }
    }

    &:nth-child(2) {
      #{$self}-row__decor {
        top: -97%;
        left: 10%;

        @include below($xl-max) {
          left: 0;
        }
      }
    }

    &:nth-child(3) {
      @include above($xl-min) {
        margin-top: 230px;
      }

      #{$self}-row__decor {
        top: -46%;
        right: 20%;

        @include below($xl-max) {
          top: -49%;
        }
      }
    }

    &:nth-child(4) {
      @include above($xl-min) {
        margin-top: 190px;
      }

      #{$self}-row__decor {
        top: -60%;
        left: 8%;

        @include below($xl-max) {
          left: 0;
        }
      }
    }

    &__decor {
      width: initial;
      padding: 0;
      position: absolute;
      z-index: -1;
      pointer-events: none;

      @include below($lg-max) {
        display: none;
      }
    }

  }

  &__pre-title {
    display: inline-block;
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    color: $green;

    &_thin {
      font-weight: 500;
    }

    @include below($md-max) {
      margin-bottom: 20px;
    }
  }

  &__btn {
    @include buttonMarginTop50;
  }
}