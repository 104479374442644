$black: #000;
$mirage: #14142B;
$steelGray: #262338;
$white: #fff;
$alabaster: #FCFCFC;
$green: #80C342;
$zanah: #E6F1DF;
$turquoise: #60C3AD;
$yellow: #FFF32A;
$pink: #DD59A0;
$violet: #7C51A1;
$blue: #49479D;
$darkBlue: #27235C;
$mischka: #D9DBE9;
$mulledWine: #4E4B66;
$cadetBlue: #A0A3BD;
$gradientGreen: linear-gradient(180deg, #80C342 0%, #56B200 100%);
$whiteLilac: #F7F7FC;
$stormGray: #6E7191;
$feijoa: #A9D48C;
$gradientWhiteGreen: linear-gradient(270deg, rgba(134, 188, 37, 0.2) 0%, rgba(134, 188, 37, 0) 50%);

$filterAlabaster: invert(92%) sepia(0%) saturate(2925%) hue-rotate(31deg) brightness(106%) contrast(106%);
$filterMirage: invert(8%) sepia(9%) saturate(3861%) hue-rotate(202deg) brightness(100%) contrast(98%);
$filterGreen: invert(68%) sepia(70%) saturate(384%) hue-rotate(47deg) brightness(89%) contrast(92%);
$filterStromGray: invert(50%) sepia(7%) saturate(1523%) hue-rotate(197deg) brightness(88%) contrast(87%);

$radius: 7px;
$radiusBig: 16px;
$shadow: 0px 8px 32px 0px rgba(20, 20, 43, 0.08);