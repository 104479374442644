.hero {
  $self: &;
  padding-top: 350px;
  padding-bottom: 200px;
  position: relative;
  background: center/auto no-repeat var(--bg-img);
  overflow: hidden;

  @include below($xl-max) {
    padding-top: 300px;
  }

  @include below($md-max) {
    padding-top: 150px;
    padding-bottom: 50px;
  }

  @include below($xs-max) {
    padding-top: 130px;
  }

  &__row {
    position: relative;
    z-index: 1;
    text-align: center;
  }

  &__text {
    margin-top: 25px;
  }

  &__btns {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;

    @include below($md-max) {
      flex-direction: column;
    }
  }

  &_product {
    @include above($lg-min) {
      padding-top: 225px;
      padding-bottom: 50px;
    }

    #{$self} {
      &__row {
        row-gap: 40px;
        text-align: start;
      }

      &__btns {
        justify-content: start;
        flex-wrap: wrap;

        @include above($sm-min) {
          flex-direction: row;
        }

        @include below($md-max) {
          margin-top: 30px;
        }
      }

      &__img {
        border-radius: 0 0 50px 50px;

        @include below($lg-max) {
          border-radius: 50px;
        }

        &_top {
          @include above($xl-min) {
            margin-top: -225px;
          }
        }
      }
    }

    ~ .title-text {
      @include above($xl-min) {
        padding-top: 30px;
      }
    }
  }
}