%svg-common {
    background: url("../img/sprite.svg") no-repeat;
}

.svg-ai {
    @extend %svg-common;
    background-position: 95.45454545454545% 31.567489114658926%;
    width: 52px;
    height: 52px;
}

.svg-ai-sm {
    @extend %svg-common;
    background-position: 95.1310861423221% 42.621776504298%;
    width: 34px;
    height: 34px;
}

.svg-arrow-down {
    @extend %svg-common;
    background-position: 98.95191122071516% 29.014084507042252%;
    width: 14px;
    height: 10px;
}

.svg-arrow-right {
    @extend %svg-common;
    background-position: 99.50738916256158% 30.851063829787233%;
    width: 12px;
    height: 20px;
}

.svg-car {
    @extend %svg-common;
    background-position: 98.05764411027569% 27.62589928057554%;
    width: 40px;
    height: 40px;
}

.svg-car-light {
    @extend %svg-common;
    background-position: 96.99248120300751% 36.18705035971223%;
    width: 40px;
    height: 40px;
}

.svg-circles {
    @extend %svg-common;
    background-position: 100% 0;
    width: 192px;
    height: 192px;
}

.svg-cloud-sun {
    @extend %svg-common;
    background-position: 99.49874686716792% 36.18705035971223%;
    width: 40px;
    height: 40px;
}

.svg-ellipse-left {
    @extend %svg-common;
    background-position: 0 0;
    width: 573px;
    height: 1430px;
}

.svg-ellipse-right {
    @extend %svg-common;
    background-position: 74.90196078431373% 0;
    width: 871px;
    height: 1430px;
}

.svg-engine {
    @extend %svg-common;
    background-position: 90.47619047619048% 39.92805755395683%;
    width: 40px;
    height: 40px;
}

.svg-iso-badge {
    @extend %svg-common;
    background-position: 92.09183673469387% 31.938325991189426%;
    width: 68px;
    height: 68px;
}

.svg-linkedin {
    @extend %svg-common;
    background-position: 91.68734491315136% 45.16358463726885%;
    width: 24px;
    height: 24px;
}

.svg-logo {
    @extend %svg-common;
    background-position: 95.31353135313532% 27.846265409717187%;
    width: 121px;
    height: 51px;
}

.svg-map {
    @extend %svg-common;
    background-position: 92.98245614035088% 39.92805755395683%;
    width: 40px;
    height: 40px;
}

.svg-minus-circle {
    @extend %svg-common;
    background-position: 99.81343283582089% 27.38944365192582%;
    width: 28px;
    height: 28px;
}

.svg-money {
    @extend %svg-common;
    background-position: 98.73737373737374% 31.567489114658926%;
    width: 52px;
    height: 52px;
}

.svg-money-sm {
    @extend %svg-common;
    background-position: 97.25343320848938% 42.621776504298%;
    width: 34px;
    height: 34px;
}

.svg-plus-circle {
    @extend %svg-common;
    background-position: 99.75124378109453% 39.58630527817404%;
    width: 28px;
    height: 28px;
}

.svg-road {
    @extend %svg-common;
    background-position: 95.48872180451127% 39.92805755395683%;
    width: 40px;
    height: 40px;
}

.svg-road-sign {
    @extend %svg-common;
    background-position: 97.99498746867168% 39.92805755395683%;
    width: 40px;
    height: 40px;
}

.svg-siren {
    @extend %svg-common;
    background-position: 90.47619047619048% 42.805755395683455%;
    width: 40px;
    height: 40px;
}

.svg-stats {
    @extend %svg-common;
    background-position: 91.16161616161617% 36.502177068214806%;
    width: 52px;
    height: 52px;
}

.svg-stats-sm {
    @extend %svg-common;
    background-position: 99.37578027465668% 42.621776504298%;
    width: 34px;
    height: 34px;
}

.svg-user {
    @extend %svg-common;
    background-position: 92.98245614035088% 42.805755395683455%;
    width: 40px;
    height: 40px;
}

.svg-user-heart {
    @extend %svg-common;
    background-position: 94.44444444444444% 36.502177068214806%;
    width: 52px;
    height: 52px;
}

.svg-user-heart-sm {
    @extend %svg-common;
    background-position: 90.13732833957553% 45.48710601719198%;
    width: 34px;
    height: 34px;
}

.svg-waves {
    @extend %svg-common;
    background-position: 100% 15.508885298869144%;
    width: 192px;
    height: 192px;
}

