.horizontal {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 200px;

  @include below($xl-max) {
    padding-bottom: 150px;
  }

  @include below($lg-max) {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @include below($md-max) {
    padding-top: 25px;
    padding-bottom: 50px;
  }

  &__decor {
    position: absolute;
    top: -50%;
    left: 0;
    z-index: -1;
  }

  &__wrapper {
    padding: 55px 50px;
    border-radius: $radiusBig;
    border: 1px solid $mischka;
    background-color: $whiteLilac;

    @include wrapperMobPaddings;
  }

  &__row {
    row-gap: 30px;
  }

  &__form {
    margin-top: 30px;
  }
}