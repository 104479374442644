.title-btn {
  padding-top: 100px;
  padding-bottom: 125px;

  @include below($xl-max) {
    padding-bottom: 100px;
  }

  @include below($lg-max) {
    padding-top: 50px;
  }

  @include below($md-max) {
    padding-top: 25px;
    padding-bottom: 50px;
  }

  &__btn {
    @include buttonMarginTop50;
  }
}