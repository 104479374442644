.card-stats {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 65px 12px;
  border-radius: $radiusBig;
  border: 1px solid $mischka;
  background-color: $whiteLilac;
  text-align: center;

  &:before {
    @extend .svg-k2g;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
  }

  @include below($md-max) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_circles {
    &:before {
      @extend .svg-circles;
    }
  }

  &_waves {
    &:before {
      @extend .svg-waves;
    }
  }


  &__title {
    font-size: 64px;
    font-weight: 600;
    line-height: 100%;
    color: $mirage;

    @include below($md-max) {
      font-size: 46px;
    }

    @include below($xs-max) {
      font-size: 40px;
    }
  }

  &__text {
    margin-top: 25px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $mulledWine;

    @include below($md-max) {
      font-size: 30px;
    }
  }
}