.k2g-btn {
  position: relative;
  padding: 10px 46px 10px 22px;
  border: 2px solid $mirage;
  border-radius: $radius;
  font-size: 20px;
  line-height: 30px;
  color: $mirage;

  &:after {
    @extend .svg-k2g;
    @extend .svg-arrow-right;
    position: absolute;
    top: 50%;
    right: 15px;
    content: '';
    transform: translateY(-50%);
    filter: $filterMirage;

    @include above($lg-min) {
      transition: right .2s ease-in-out;
    }
  }

  &_sm {
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    border-color: $green;
    color: $green;

    &:after {
      filter: $filterGreen;

      @include above($lg-min) {
        right: 10px;
      }
    }
  }

  @include below($xs-max) {
    width: 100%;
    text-align: center;
  }

  &_bg {
    position: relative;
    padding: 12px 48px 12px 24px;
    border: unset;
    background: $gradientGreen;
    color: $alabaster;
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    &:after {
      filter: $filterAlabaster;
    }

    &:hover {
      color: $mirage;

      &:after {
        filter: $filterMirage;
      }
    }
  }

  &_transparent {
    padding: 12px 48px 12px 24px;
    border: unset;
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    color: $mirage;

    &:after {
      filter: $filterMirage;
    }

    &:hover {
      color: $stormGray;

      &:after {
        filter: $filterStromGray;
      }
    }
  }
}