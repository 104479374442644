.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

  @include below($md-max) {
    padding-top: 10px;
  }

  .container {
    max-width: 1296px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: $whiteLilac;
    border-radius: 24px;
    transition: .2s ease;

    @include below($xl-max) {
      max-width: 1116px;
    }

    @include below($lg-max) {
      max-width: 936px;
    }

    @include below($md-max) {
      max-width: 696px;
    }

    @include below($sm-max) {
      max-width: 516px;
    }

    @include below($xs-max) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  &_thin {
    .container {
      box-shadow: 0 1px 10px 0 rgba(20, 20, 43, 0.08);
    }
  }

  &:has(.navbar-collapse.collapse.show),
  &:has(.navbar-collapse.collapsing) {
    height: 100dvh;
    overflow-y: auto;
    background: $white;

    .container {
      padding-bottom: 24px;
    }
  }

  .navbar {
    padding: 0;

    &-nav {
      @include below($md-max) {
        display: none;
      }
    }

    &-brand {
      padding: 0;
      margin-right: unset;
      line-height: 0;
    }

    &-toggler {
      padding: 0;
      background-image: unset;
      border: none;

      &:focus {
        box-shadow: unset;
      }

      &__icon {
        position: relative;
        display: block;
        width: 30px;
        height: 25px;
        margin: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
          position: absolute;
          left: 0;
          height: 3px;
          width: 100%;
          display: block;
          border-radius: 50px;
          background-color: $green;
          transition: .3s ease;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            width: 80%;
            top: 10px;
          }

          &:nth-child(3) {
            width: 60%;
            top: 20px;
          }
        }
      }

      &:not(.collapsed) {
        .navbar-toggler__icon span:nth-child(1) {
          top: 11px;
          transform: rotate(135deg);
        }

        .navbar-toggler__icon span:nth-child(2) {
          opacity: 0;
          left: -60px;
        }

        .navbar-toggler__icon span:nth-child(3) {
          top: 11px;
          width: 100%;
          transform: rotate(-135deg);
        }
      }
    }

    &-nav {
      margin-left: auto;
      gap: 12px;

      @include below($xl-max) {
        gap: 15px;
      }

      @include below($lg-max) {
        gap: 10px
      }

      .nav-item {
        border-radius: $radius;
        font-weight: 500;

        &:hover {
          background-color: $zanah;
        }

        &.dropdown {
          padding-right: 20px;

          @include below($xl-max) {
            padding-right: 15px;
          }

          @include below($lg-max) {
            padding-right: 10px;
          }

          &:after {
            @extend .svg-k2g;
            @extend .svg-arrow-down;
            content: '';
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            transition: transform 0.3s ease;

            @include below($xl-max) {
              right: 0;
            }
          }

          .nav-link {
            pointer-events: none;
            cursor: default;
          }

          &:hover {
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
              content: "";
              height: 70px;
              width: 100%;
            }

            &:after {
              transform: translateY(-50%) rotate(180deg);
            }

            .dropdown-menu {
              display: block;
            }
          }

          &_lang {
            .dropdown-menu {
              left: unset;
              transform: unset;
              right: -12px;
            }
          }
        }

        &_black {
          background-color: $mirage;

          .nav-link {
            padding-left: 24px;
            padding-right: 24px;

            @include below($xl-max) {
              padding-right: 15px;
              padding-left: 15px;
            }

            @include below($lg-max) {
              padding: 5px 8px;
            }

            &:not(:hover) {
              color: $alabaster;
            }
          }
        }
      }

      .nav-link {
        padding: 10px;
        color: $steelGray;

        @include below($xl-max) {
          padding: 8px;
        }

        @include below($lg-max) {
          padding: 5px;
          font-size: 15px;
        }

        &.dropdown-toggle {
          &:after {
            content: unset;
          }
        }
      }

      .dropdown-menu {
        left: 50%;
        min-width: 200px;
        padding: 8px;
        margin-top: 15px;
        border-radius: $radiusBig;
        border: 1px solid $mischka;
        background: $alabaster;
        box-shadow: $shadow;
        transform: translateX(-50%);

        &_mega {
          padding: 0;
          width: 100%;
          min-width: 910px;

          @include below(1500px) {
            min-width: 800px;
          }

          @include below($lg-max) {
            left: -50%;
          }

          &-first {
            @include below($xl-max) {
              left: 0;
              transform: unset;
            }
          }

          .dropdown-item {
            position: relative;
            padding: 10px 10px 10px 70px;
            display: inline-flex;
            flex-direction: column;
            border-radius: $radius;
            font-size: 20px;
            line-height: 30px;

            @include below($lg-max) {
              padding-left: 55px;
              padding-right: 0;
              font-size: 18px;
              line-height: 26px;
            }

            &:before {
              @extend .svg-k2g;
              position: absolute;
              top: 10px;
              left: 0;
              content: '';
            }

            &_ai {
              &:before {
                @extend .svg-ai;
              }
            }

            &_stats {
              &:before {
                @extend .svg-stats;
              }
            }

            &_money {
              &:before {
                @extend .svg-money;
              }
            }

            &_user-heart {
              &:before {
                @extend .svg-user-heart;
              }
            }

            span {
              font-size: 14px;
              line-height: 21px;
              font-weight: 400;
            }
          }
        }

        &__row {
          margin: 0;
          padding: 40px;
          row-gap: 30px;

          @include below($xl-max) {
            padding: 30px 20px;
          }

          @include below($lg-max) {
            padding: 20px 0;
            row-gap: 20px;
          }
        }

        li {
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      .dropdown-item {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        white-space: initial;
        border-radius: $radius;

        @include below($lg-max) {
          font-size: 15px;
          line-height: 1.2;
        }

        &:hover,
        &:active {
          background-color: $zanah;
          color: $mirage;
        }
      }
    }
  }

  .accordion-menu {
    margin-top: 20px;

    @include above($lg-min) {
      display: none;
    }

    &-item {
      &:first-child {
        border-top: 1px solid $green;
      }
    }

    &__link,
    &__link-simple {
      width: 100%;
      padding: 10px 30px 10px 12px;
      border-bottom: 1px solid $green;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $steelGray;
    }

    &__link {
      position: relative;

      &:after {
        @extend .svg-k2g;
        @extend .svg-arrow-down;
        position: absolute;
        top: 50%;
        right: 12px;
        content: '';
        transform: translateY(-50%);
        filter: $filterMirage;
        transition: 0.3s ease;
      }

      &:not(.collapsed) {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &-collapse {
      padding-left: 12px;
      padding-right: 12px;

      &.collapsing,
      &.show {
        border-bottom: 1px solid $green;
      }

      &-list {
        li {
          &:not(:last-child) {
            border-bottom: 1px solid $green;
          }
        }

        &__link {
          width: 100%;
          padding: 10px 0 10px 20px;
          font-weight: 400;
          color: $steelGray;

          &_icon {
            position: relative;
            padding: 10px 0 10px 40px;

            &:before {
              @extend .svg-k2g;
              position: absolute;
              top: 50%;
              left: 0;
              content: '';
              transform: translateY(-50%);
            }
          }


          &_ai {
            &:before {
              @extend .svg-ai-sm;
            }
          }

          &_stats {
            &:before {
              @extend .svg-stats-sm;
            }
          }

          &_money {
            &:before {
              @extend .svg-money-sm;
            }
          }

          &_user-heart {
            &:before {
              @extend .svg-user-heart-sm;
            }
          }
        }
      }
    }
  }
}