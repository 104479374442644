.f-semi {
  font-weight: 600;
}

.f-bold {
  font-weight: 700;
}

.c-mulled-wine {
  color: $mulledWine !important;
}