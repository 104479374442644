.side {
  padding-top: 200px;
  padding-bottom: 250px;

  @include below($xl-max) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include paddingsLg50;
  @include paddingsMob25;

  &__wrapper {
    position: relative;
    z-index: 1;

    @include rowWrapperWhiteGreen;

    @include below($md-max) {
      padding: 40px 30px 12px 30px;
    }
  }

  &__row {
    row-gap: 30px;
    align-items: center;
  }

  &__img {
    position: absolute;
    top: -23%;
    right: 0;

    @include below($xl-max) {
      width: 500px;
      top: -13%;
    }

    @include below($lg-max) {
      top: 2%;
    }

    @include below($md-max) {
      position: static;
      width: unset;
    }
  }

  &__btn {
    @include buttonMarginTop50;
  }
}