@mixin paddingsLg50 {
  @include below($lg-max) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@mixin paddingsMob50 {
  @include below($md-max) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@mixin paddingsMob25 {
  @include below($md-max) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@mixin paddingBottomMob25 {
  @include below($md-max) {
    padding-bottom: 25px;
  }
}

@mixin cardMobPaddings {
  @include below($md-max) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include below($xs-max) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@mixin wrapperMobPaddings {
  @include below($md-max) {
    padding: 40px 30px;
  }

  @include below($xs-max) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@mixin buttonMarginTop50 {
  margin-top: 50px;

  @include below($md-max) {
    margin-top: 30px;
  }
}

@mixin rowWrapperWhiteGreen {
  padding: 55px 50px;
  border-radius: $radiusBig;
  border: 1px solid $mischka;
  background: right/auto no-repeat url('https://45045908.fs1.hubspotusercontent-na1.net/hubfs/45045908/k2g-website/images/images/green-gradient.webp');

  @include wrapperMobPaddings;

  @include below($md-max) {
    background-position: left;
    background-size: cover;
  }
}