.error-page {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: right/auto no-repeat url('https://45045908.fs1.hubspotusercontent-na1.net/hubfs/45045908/k2g-website/images/images/green-gradient.webp');
  overflow: hidden;
  text-align: center;

  @include below($md-max) {
    padding-top: 150px;
    padding-bottom: 50px;
    background-position: left;
    background-size: cover;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  &__pre-title {
    display: block;
    color: $stormGray;
    font-size: 45px;
    line-height: 1;
  }
}