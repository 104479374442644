.parallax {
  position: relative;
  z-index: 1;
  padding-top: 185px;
  padding-bottom: 185px;
  overflow-x: hidden;

  @include above($xxl-min) {
    margin-top: 100px;
  }
  @include below($lg-max) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include paddingsMob50;

  @include below($md-max) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  &__row {
    justify-content: center;
    text-align: center;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    will-change: transform;
    pointer-events: none;

    @include below($lg-max) {
      object-fit: cover;
    }
  }

  &__btn {
    @include buttonMarginTop50;
  }
}