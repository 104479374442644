.two-cols-form {
  padding-top: 120px;
  padding-bottom: 180px;

  @include below($xl-max) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include paddingsLg50;
  @include paddingsMob25;

  &__wrapper {
    @include rowWrapperWhiteGreen;
  }

  &__inner {
    @include above($xxl-min) {
      padding-right: 45px;
      padding-left: 45px;
    }
  }

  &__content {
    margin-top: 15px;
  }
}