.card-testimonials {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  padding: 50px 35px;
  border-radius: 16px;
  border: 1px solid $mischka;
  background: $white;
  box-shadow: $shadow;

  @include below($lg-max) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include cardMobPaddings;

  &__text {
    margin-bottom: 50px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $mulledWine;

    @include below($md-max) {
      margin-bottom: 30px;
    }

    @include below($xs-max) {
      font-size: 20px;
    }
  }

  &__author {
    &-name,
    &-position {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;

      @include below($xs-max) {
        font-size: 16px;
      }
    }

    &-name {
      margin-top: auto;
      font-weight: 600;
      color: $mirage;
    }


    &-position {
      color: $mulledWine;
    }
  }

  &__img {
    margin-top: 10px;
  }
}