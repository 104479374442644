.partners {
  padding-top: 115px;
  padding-bottom: 75px;

  @include below($lg-max) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @include below($md-max) {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__text {
    text-align: center;
    color: $stormGray;
  }

  &-swiper {
    margin-top: 50px;

    @include below($md-max) {
      margin-top: 30px;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto !important;
    }
  }

  &__logo {
    &:hover {
      filter: $filterGreen;
    }
  }
}