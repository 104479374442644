.left-content {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 220px;

  @include above($xxl-min) {
    margin-top: 150px;
  }

  @include below($xl-max) {
    padding-bottom: 100px;
  }

  @include below($lg-max) {
    padding-bottom: 50px;
  }

  @include paddingsMob25;

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @include below($xl-max) {
      right: -20%;
    }

    @include below($md-max) {
      right: -40%;
      opacity: 0.5;
    }

    @include below($sm-max) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include below($xs-max) {
      display: none;
    }
  }

  &__btn {
    @include buttonMarginTop50;
  }
}