.sources {
  padding-bottom: 75px;

  @include below($lg-max) {
    padding-bottom: 50px;
  }

  @include paddingBottomMob25;

  &-icons-swiper {
    &-left,
    &-right {
      -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
      mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    }
  }

  &__row {
    text-align: center;
  }

  &__wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__icon {
    width: 85px;
    height: 85px;
    padding: 20px;
    background-color: transparent;
    border: 2px solid $mischka;
    border-radius: 50%;
    line-height: 0;
    transition: transform 0.3s ease;

    @include below($md-max) {
      width: 65px;
      height: 65px;
      padding: 10px;
    }

    i {
      filter: $filterMirage;
      transition: filter 0.3s ease;
    }

    &:hover {
      background-color: $green;
      border-color: $feijoa;
      transform: scale(1.15);

      i {
        filter: invert(1);
      }
    }
  }

  &__btn {
    margin-top: 40px;
  }
}