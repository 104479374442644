.statistics {
  padding-bottom: 65px;

  @include below($lg-max) {
    padding-bottom: 50px;
  }

  @include paddingBottomMob25;

  &__row {
    justify-content: center;
    text-align: center;
  }

  &__cards-row {
    row-gap: 30px;

    @include above($lg-min) {
      margin-top: 15px;
    }
  }

  &__text {
    margin-top: 10px;
  }
}