.card-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 20px;
  padding: 20px 15px;
  background-color: $white;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include below($xs-max) {
    flex-direction: column;
    align-items: start;
    padding-left: 12px;
    padding-right: 12px;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 20px;
  }


  &__img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: $steelGray;
  }

  &__text {
    margin-top: 5px;
    line-height: 20px;
    color: $stormGray;
  }

  &__btn {
    position: relative;
    z-index: 1;
    padding-right: 35px;
    color: $mirage;

    &:after {
      @extend .svg-k2g;
      @extend .svg-arrow-right;
      position: absolute;
      top: 50%;
      right: 10px;
      content: '';
      transform: translateY(-50%);
      filter: $filterMirage;
    }

    &:hover {
      color: $green;

      &:after {
        filter: $filterGreen;
      }
    }
  }
}