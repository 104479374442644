@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block
}

audio, canvas, video {
  display: inline-block
}

audio:not([controls]) {
  display: none
}

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  // custom scrollbar
  &::-webkit-scrollbar {
    background: $alabaster;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $alabaster;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: $green;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $mirage;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: $green;
  }

  &::-webkit-scrollbar-button {
    display: none
  }
}

// Green content selection
*::selection {
  background: $green;
  color: $mirage;
}
*::-moz-selection {
  background: $green;
  color: $mirage;
}
*::-webkit-selection {
  background: $green;
  color: $mirage;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $alabaster;
  overflow: hidden;
}

// Sticky Footer
footer {
  margin-top: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

a:active, a:hover {
  outline: 0;
}

sub, sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

// HTML5 display-role reset for older browsers
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #8a8a8a;
}

::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #8a8a8a;
}

:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #8a8a8a;
}

:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #8a8a8a;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

img:hover,
img,
button[type="submit"]:hover,
button[type="submit"],
a,
li,
a:hover,
li:hover,
span,
i,
span:hover,
input[type="submit"],
input[type="submit"]:hover,
button,
svg {
  text-decoration: none !important;
  transition: color 0.3s ease, background 0.3s ease, filter 0.3s ease, border-color 0.3s ease;
}

a i {
  transition: none;
}

a {
  display: inline-block;
  font-weight: 500;

  &:before,
  &:after {
    transition: filter 0.3s ease;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.z-index-1 {
  z-index: 1;
}

.lh-0 {
  line-height: 0;
}

// bootstrap classes
.text-right {
  text-align: right !important;
}

.text-transform-none {
  text-transform: none !important;
}

.m-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-md-t-40 {
  @include below($md-max) {
    margin-top: 40px;
  }
}