.title-text {
  padding-top: 75px;
  padding-bottom: 35px;

  @include below($lg-max) {
    padding-top: 50px;
  }

  @include paddingsMob25;

  &__row {
    justify-content: center;
    text-align: center;
  }
}