.regular {

  padding-top: 200px;
  padding-bottom: 100px;

  @include below($md-max) {
    padding-top: 150px;
    padding-bottom: 50px;
  }

  @include below($xs-max) {
    padding-top: 130px;
  }

  &_decor {
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      @extend .svg-k2g;
      position: absolute;
      content: '';
      z-index: -1;
      opacity: .5;
    }

    &:before {
      @extend .svg-ellipse-right;
      top: 10%;
      right: 0;
    }

    &:after {
      @extend .svg-ellipse-left;
      bottom: 0;
      left: 0;
    }
  }
}