.benefits {
  padding-top: 15px;
  padding-bottom: 150px;

  @include below($xl-max) {
    padding-bottom: 100px;
  }

  @include below($lg-max) {
    padding-bottom: 50px;
  }

  @include paddingBottomMob25;

  &__row {
    row-gap: 20px;
  }
}