.testimonials {
  position: relative;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 150px;

  @include below($xl-max) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include paddingsLg50;
  @include paddingsMob25;

  &__decor {
    position: absolute;
    top: -10%;
    right: 0;
    z-index: -1;

    @include below($md-max) {
      top: 0;
    }
  }

  &__row {
    margin-top: 50px;
    row-gap: 30px;

    @include below($md-max) {
      margin-top: 30px;
    }
  }

  &__btn {
    @include buttonMarginTop50;
  }
}