.accordion {
  &-item {
    padding: 30px 30px 30px 80px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: $radiusBig;
    transition: .1s ease;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:has(.accordion-collapse.collapse.show),
    &:has(.accordion-collapse.collapsing) {
      background-color: $white;
      border-color: $mischka;
      box-shadow: $shadow;
    }

    @include below($sm-max) {
      padding: 30px 12px 30px 50px;
    }

    @include below($xs-max) {
      padding: 20px 12px 20px 50px;
    }
  }

  &__button {
    position: relative;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: $mirage;
    cursor: pointer;

    &:hover {
      color: $green;
    }

    &:before {
      @extend .svg-k2g;
      @extend .svg-minus-circle;
      position: absolute;
      top: 0;
      left: -50px;
      content: '';

      @include below($sm-max) {
        left: -40px;
      }

      @include below($xs-max) {
        top: -2px;
      }
    }

    &.collapsed {
      &:before {
        @extend .svg-plus-circle;
      }
    }

    @include below($xs-max) {
      font-size: 20px;
      line-height: 120%;
    }
  }

  &__body {
    padding-top: 10px;
    color: $mulledWine;
  }

  &-section {
    padding-bottom: 130px;

    @include below($xl-max) {
      padding-bottom: 100px;
    }

    @include below($lg-max) {
      padding-bottom: 50px;
    }

    @include paddingsMob25;

    &__row {
      margin-top: 10px;
      justify-content: center;
    }
  }
}